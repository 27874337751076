export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = "http://localhost:5000"

export const UserTypes = {
  SUPER: 0,
  ADMIN: 1,
  CHEFAG: 2,
  CHEFAT: 3,
  CAPTEUR: 4,
};
