import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function MaintenanceCreation({ machineId, token }) {
  const [isCreatingMaintenance, setIsCreatingMaintenance] = useState(false);
  const navigate = useNavigate();
  const [allMaintenances, setAllMaintenances] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [matchingMaintenances, setMatchingMaintenances] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [maintenanceData, setMaintenanceData] = useState({
    date: new Date().toISOString(),
    nom: "",
    description: "",
    etat: "A_JOUR",
    freq_intervention: 0,
    datemaintenance: "",
    maintenance: {
      id: "a76cab8f-d158-4af3-87ce-2aec72f2ad97",
    },
    machine: {
      id: machineId,
    },
  });

  useEffect(() => {
    // Obtenez toutes les maintenances au chargement du composant
    axios
      .get(`${API_URL}/maintenancesmachine`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const allMaintenances = response.data.data;
        setAllMaintenances(allMaintenances);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  useEffect(() => {
    // Filtrez la liste complète des maintenances en fonction du terme de recherche
    if (searchTerm.trim() !== "") {
      const filteredMaintenances = allMaintenances.filter((maintenance) =>
        maintenance.nom.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Utilisez un ensemble (Set) pour stocker les noms uniques
      const uniqueNames = new Set(filteredMaintenances.map((maintenance) => maintenance.nom));

      // Convertissez l'ensemble en tableau pour l'affichage
      setMatchingMaintenances([...uniqueNames]);
    } else {
      // Réinitialisez la liste si le champ de recherche est vide
      setMatchingMaintenances([]);
    }
  }, [searchTerm, allMaintenances]);

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    
    // Vérification et conversion de freq_intervention en nombre entier
    const newValue = name === "freq_intervention" ? 
      (isNaN(value) ? 0 : parseInt(value)) : value;
  
    if (name === "nom") {
      setSearchTerm(newValue);
    }
  
    setMaintenanceData({ ...maintenanceData, [name]: newValue });
  };
  

  const handleCancelClick = () => {
    setIsCreatingMaintenance(false);
    setMaintenanceData({
      freq_intervention: 0,
      date: new Date().toISOString(),
      nom: "",
      description: "",
      etat: "A_JOUR",
      maintenance: {
        id: "a76cab8f-d158-4af3-87ce-2aec72f2ad97",
      },
      machine: {
        id: machineId,
      },
    });
  };

  const checkDuplicateMaintenance = async (machineId, maintenanceName) => {
    try {
      const response = await axios.get(
        `${API_URL}/maintenancesmachine/machines/${machineId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const maintenances = response.data;
      return maintenances.some((maintenance) => maintenance.nom === maintenanceName);
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  

  const handleCreateMaintenance = async () => {
    const isDuplicate = await checkDuplicateMaintenance(
      machineId,
      maintenanceData.nom
    );
  
    if (isDuplicate) {
      setIsDuplicate(true)
      setTimeout(() => {
        setIsDuplicate(false);
      }, 3000);
    } else {
      axios
        .post(
          `${API_URL}/maintenancesmachine`,
          { ...maintenanceData, heures: maintenanceData.freq_intervention },
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          if (response.status === 401) {
            showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
            localStorage.clear();
            navigate.push("/login");
          } else {
            setIsCreatingMaintenance(false);
            setMaintenanceData({
              heures: 0,
              date: new Date().toISOString(),
              nom: "",
              description: "",
              etat: "A_JOUR",
              freq_intervention: 0,
              datemaintenance : "",
              maintenance: {
                id: "a76cab8f-d158-4af3-87ce-2aec72f2ad97",
              },
              machine: {
                id: machineId,
              },
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  
  return (
    <div className="my-4">
     {isCreatingMaintenance ? (
        <div className="flex flex-col space-y-6 items-center p-6 bg-gray-50 shadow-lg rounded-lg w-full max-w-4xl mx-auto">
          <div className="grid sm:grid-cols-2 gap-6 w-full">
          <input
              type="text"
              name="nom"
              value={maintenanceData.nom}
              onChange={handleInputChange}
              placeholder="Nom de la maintenance"
              className="border border-gray-300 rounded-lg px-4 py-3 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 w-full"
            />
            <input
              type="text"
              name="description"
              value={maintenanceData.description}
              onChange={handleInputChange}
              placeholder="Description de la maintenance"
              className="border border-gray-300 rounded-lg px-4 py-3 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 w-full"
            />
          </div>
          <div className="grid sm:grid-cols-2 gap-6 w-full relative">
            <input
              type="number"
              name="freq_intervention"
              value={maintenanceData.freq_intervention === 0 ? '' : maintenanceData.freq_intervention}
              onChange={handleInputChange}
              placeholder="Fréquence d'intervention (heures)"
              className="border border-gray-300 rounded-lg px-4 py-3 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 w-full"
            />
            {matchingMaintenances.length > 0 && (
              <ul className="suggestions-list absolute left-0 right-0 mt-10 bg-white border border-gray-300 rounded-lg shadow-lg w-full">
                {matchingMaintenances.map((maintenanceName) => (
                  <li
                    key={maintenanceName}
                    onClick={() => {
                      const matchingMaintenance = allMaintenances.find(
                        (maintenance) => maintenance.nom === maintenanceName
                      );
                      if (matchingMaintenance) {
                        setMaintenanceData({
                          ...maintenanceData,
                          description: matchingMaintenance.description,
                          heures: matchingMaintenance.heures,
                          nom: matchingMaintenance.nom,
                        });
                      }
                      setMatchingMaintenances([]);
                      setSearchTerm("");
                    }}
                    className="cursor-pointer px-4 py-3 hover:bg-gray-200 transition-all duration-200"
                  >
                    {maintenanceName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 mt-6 sm:mt-4 w-full">
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg font-semibold transition-all duration-300 w-full sm:w-auto"
              onClick={handleCreateMaintenance}
            >
              Valider
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-red-600 hover:bg-red-700 text-white px-6 py-3 rounded-lg font-semibold transition-all duration-300 w-full sm:w-auto"
            >
              Annuler
            </button>
          </div>
          {isDuplicate && (
            <div className="bg-red-500 text-white text-lg mt-4 p-4 rounded-lg shadow-lg w-full sm:w-auto">
              Une maintenance avec ce nom existe déjà pour cette machine.
            </div>
          )}
        </div>
      ) : (
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold text-xl sm:text-2xl rounded-3xl py-4 px-8 3xl:text-4xl 3xl:px-12 3xl:py-6 mx-auto transition-all duration-300 w-full sm:w-auto"
          onClick={() => setIsCreatingMaintenance(true)}
        >
          Créer une maintenance exclusive
        </button>
      )}

    </div>
  );
}
